const config = {
    pollingTime: 2000,
    client: {
        url: 'https://stalwartcentral.com',
    },
    api: {
        url: 'https://api.stalwartcentral.com',
    },
    ws: {
        url: 'wss://ws.stalwartcentral.com',
    },
};

export default config;
