import React from 'react';
import _ from 'lodash';
import { Link, Navigate } from 'react-router-dom';
import * as queryString from 'query-string';

import Client from '../../../lib/client';
import { CustomRouteProps } from '../../../lib/components/route';
import logoIcon from '../images/logo-icon.svg';
import signin from '../images/element/signin.svg';

type State = {
  loading: boolean;
  username: string;
  publicCode?: string;
  message?: {
    type: 'success' | 'danger';
    text: string;
  }
}

export default class SignIn extends React.Component<CustomRouteProps, State> {
  constructor(props: CustomRouteProps) {
    super(props);
    this.state = {
      loading: false,
      username: '',
    };
  }

  onChangeText = (field: 'username', event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    this.setState({ username: event.target.value });
  }

  onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });

      try {
        const client = new Client();
        const publicCode = await client.api.auth.requestVerificationCode(
          this.state.username,
        );

        this.setState({
          publicCode,
          loading: false,
          message: undefined
        });
      } catch (error) {
        this.setState({
          loading: false,
          publicCode: undefined,
          message: {
            type: 'danger',
            text: _.get(error, 'message', 'Unable to sign in. Please try again!'),
          }
        });
      }
    }
  }

  validateForm() {
    return true;
  }

  render() {
    if (this.state.publicCode) {
      const urlValues = {
        ...this.props.urlValues,
        username: this.state.username,
        publicCode: this.state.publicCode,
      };
      const redirectUrl = queryString.stringifyUrl({ url: '/verification-code', query: urlValues });
      return <Navigate to={redirectUrl}/>
    }

    return (
      <main>  
        <section className="vh-xxl-100">
          <div className="container h-100 d-flex px-0 px-sm-4">
            <div className="row justify-content-center align-items-center m-auto">
              <div className="col-12">
                <div className="bg-mode shadow rounded-3 overflow-hidden">
                  <div className="row g-0">

                    <div className="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                      <div className="p-3 p-lg-5">
                        <img src={signin} alt="signin" />
                      </div>
                      <div className="vr opacity-1 d-none d-lg-block"></div>
                    </div>

                    <div className="col-lg-6 order-1">
                      <div className="p-4 p-sm-7">

                        <a href="index.html">
                          <img className="h-50px mb-4" src={logoIcon} alt="logo" />
                        </a>

                        <h1 className="mb-2 h3">Welcome back</h1>
                        <p className="mb-0">New here? <Link className="text-decoration-none" to={`/sign-up?${queryString.stringify(this.props.urlValues || {})}`}>Create an account!
                        </Link></p>

                        <form className="mt-4 text-start" autoComplete="off" onSubmit={this.onSubmit}>

                          {
                            this.state.message ? (
                            <div className={`alert alert-${this.state.message.type}`} role="alert">
                              {this.state.message.text}
                            </div>
                            ) : null
                          }

                          <div className="mb-3">
                            <label className="form-label">Username</label>
                            <input
                              onChange={this.onChangeText.bind(this, 'username')}
                              value={this.state.username}
                              type="text"
                              autoCapitalize="off"
                              className="form-control"
                              required={true}
                              autoFocus={true}
                              autoComplete="off"
                            />
                          </div>

                          {/* TODO: May be in the future could implement a recovery pass service */}
                          {/* <div className="mb-3 position-relative">
                            <label className="form-label">Enter password</label>
                            <input className="form-control fakepassword" type="password" id="psw-input" />
                            <span className="position-absolute top-50 end-0 translate-middle-y p-0 mt-3">
                              <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2"></i>
                            </span>
                          </div> */}

                          {/* <div className="mb-3 d-sm-flex justify-content-between">
                            <div>
                              <input type="checkbox" className="form-check-input" id="rememberCheck" />
                              <label className="form-check-label" htmlFor="rememberCheck">Remember me?</label>
                            </div>
                            <a href="forgot-password.html">Forgot password?</a>
                          </div> */}

                          <div><button type="submit" className="btn btn-primary w-100 mb-0">Sign in</button></div>
                          
                          {/* TODO: May be in the future could we use this auth services */}
                          {/*
                          <div className="position-relative my-4">
                            <hr />
                            <p className="small bg-mode position-absolute top-50 start-50 translate-middle px-2">Or sign in with</p>
                          </div>

                          <div className="vstack gap-3">
                            <a href="#" className="btn btn-light mb-0">
                              <i className="fab fa-fw fa-google text-google-icon me-2"></i>Sign in with Google
                            </a>
                            <a href="#" className="btn btn-light mb-0">
                              <i className="fab fa-fw fa-facebook-f text-facebook me-2"></i>Sign in with Facebook
                            </a>
                          </div> */}

                          <div className="text-primary-hover text-body mt-3 text-center">
                            Copyrights ©2024 BAND BMB by <a href="https://www.webestica.com/" className="text-body">CAVILLO</a>.
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
